import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ZoomInIcon from '@pelckmans/business-components/icons/ZoomIn';
import ZoomOutIcon from '@pelckmans/business-components/icons/ZoomOut';
import ZoomMaskIcon from '@pelckmans/business-components/icons/ZoomMask';
import ZoomToFitIcon from '@pelckmans/business-components/icons/ZoomToFit';

import ViewmodeToggleButton from './items/viewmodeToggleButton';
import { getViewMode, canToggleSpreadPageMode, getZoomLevel } from '../../../../selectors/navigation';
import { toggleViewMode, zoomIn, zoomOut, zoomToFit } from '../../../../actions/navigation';

import ZoomLevel from '../../../../enums/zoomLevel';
import SolutionsPanel from './items/solutions';
import { setCurrentTool } from '../../../../actions/tools';
import tools from '../../../../enums/tools';
import { getCurrentTool } from '../../../../selectors/tools';
import PlayerMode from '../../../../enums/playerMode';
import { getIsManualVisible, getIsSolutionsPageVisible, getPlayerMode, getIsPopUpManualVisible } from '../../../../selectors/player';
import { toggleManual, togglePopUpManual } from '../../../../actions/player';
import { ManualButton } from './items/manualButton';
import { PopUpManualButton } from './items/PopUpManualButton';
import ToggleMarkingsButton from './items/toggleMarkingsButton';
import AnnotationSetsButton from './items/annotationSetsButton';
import { isTeacherAlike } from '../../../../selectors/user';

const Dock = ({ digibook }) => {
  const root = 'pbb-dock';
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const viewMode = useSelector(state => getViewMode(state));
  const pageSpreadButtonEnabled = useSelector(state => canToggleSpreadPageMode(state));
  const zoomLevel = useSelector(state => getZoomLevel(state));
  const isZoomSelectionMode = useSelector(state => getCurrentTool(state) === tools.ZOOM_SELECT);
  const playerMode = useSelector(state => getPlayerMode(state));
  const isManualVisible = useSelector(state => getIsManualVisible(state));
  const isSolutionsPageVisible = useSelector(state => getIsSolutionsPageVisible(state));
  const isPopUpManualVisible = useSelector(state => getIsPopUpManualVisible(state));
  const teacherAlike = useSelector(state => isTeacherAlike(state));

  return (
    <div type="button" className={root}>
      <ul className={`${root}__wrapper`}>
        <li className={`${root}__annotations`}>
          {playerMode !== PlayerMode.WHITEPAGE && teacherAlike && <AnnotationSetsButton />}
          <ToggleMarkingsButton playerMode={playerMode} />
        </li>
        <li className={`${root}__wrapper__divider`} />
        {digibook && digibook.manual && playerMode !== PlayerMode.WHITEPAGE && (
          <>
            {digibook.manualType === 'manual-layer' && (
              <li className={`${root}__manual ${root}__manual--layer`}>
                <ManualButton isEnabled={isManualVisible} onClick={() => dispatch(toggleManual())} />
              </li>
            )}
            {digibook.manualType === 'pop-up-manual' && (
              <li className={`${root}__manual ${root}__manual--pop-up`}>
                <PopUpManualButton isEnabled={isPopUpManualVisible} onClick={() => dispatch(togglePopUpManual())} />
              </li>
            )}
            <li className={`${root}__wrapper__divider`} />
          </>
        )}
        {digibook && digibook.answerLayer && playerMode !== PlayerMode.WHITEPAGE && (
          <>
            <li className={`${root}__solutions`}>
              <SolutionsPanel digibook={digibook} />
            </li>
            <li className={`${root}__wrapper__divider`} />
          </>
        )}
        <li className={`${root}__zoom`}>
          <button
            type="button"
            className="pbb-sidebar__zoom-plus"
            onClick={() => dispatch(zoomIn())}
            disabled={zoomLevel >= ZoomLevel.MAX_ZOOM_LEVEL}
            title={t('dock.buttons.zoomIn.tooltip')}
          >
            <ZoomInIcon />
          </button>
          <button
            type="button"
            className="pbb-sidebar__zoom-min"
            onClick={() => dispatch(zoomOut())}
            disabled={zoomLevel <= ZoomLevel.MIN_ZOOM_LEVEL}
            title={t('dock.buttons.zoomOut.tooltip')}
          >
            <ZoomOutIcon />
          </button>
          <button
            type="button"
            className={classNames('pbb-sidebar__zoom-drag', {
              'pbb-active': isZoomSelectionMode,
            })}
            onClick={() => dispatch(setCurrentTool(isZoomSelectionMode ? tools.POINTER : tools.ZOOM_SELECT))}
            title={t('dock.buttons.zoomDrag.tooltip')}
          >
            <ZoomMaskIcon />
          </button>
          <button type="button" className="pbb-sidebar__zoom-1-on-1" onClick={() => dispatch(zoomToFit())} title={t('dock.buttons.zoomReset.tooltip')}>
            <ZoomToFitIcon />
          </button>
        </li>
        <li className={`${root}__wrapper__divider`} />
        <li className={`${root}__switch`}>
          <ViewmodeToggleButton
            viewMode={viewMode}
            handleToggleClick={() => dispatch(toggleViewMode())}
            disabled={!pageSpreadButtonEnabled || playerMode === PlayerMode.WHITEPAGE || isSolutionsPageVisible}
            title={t('dock.buttons.viewModeToggle.tooltip')}
          />
        </li>
      </ul>
    </div>
  );
};

Dock.propTypes = {
  digibook: shape({
    answerLayer: string,
  }),
};

Dock.defaultProps = {
  digibook: undefined,
};

export default Dock;
