import React from 'react';
import { useDispatch } from 'react-redux';
import { shape, string, number } from 'prop-types';
import EllipsisVerticalIcon from '@pelckmans/business-components/icons/EllipsisVertical';

import { useTranslation } from 'react-i18next';
import { openNotLicensedModal } from '../../../../../../actions/dialog';
import SlideSetInfo from './SlideSetInfo';

const UnlicenseSlideSetListItem = ({ slideSet }) => {
  const { name } = slideSet;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemClickHandler = async e => {
    e.preventDefault();
    dispatch(openNotLicensedModal(t('notLicensed.accessContent.title'), t('notLicensed.accessContent.message')));
  };

  return (
    <li className="pbb-list__item" data-testid={`slideSetItem-${name}`}>
      <div className="pbb-list__link-wrap pbb-list__link-wrap--disabled">
        <button type="button" className="pbb-list__link pbb-flex-wrap" title={name} onClick={itemClickHandler}>
          <SlideSetInfo slideSet={slideSet} />
          <div className="pbb-list__actions">
            <div className="pbb-list__action">
              <EllipsisVerticalIcon />
            </div>
          </div>
        </button>
      </div>
    </li>
  );
};

UnlicenseSlideSetListItem.propTypes = {
  slideSet: shape({
    id: number.isRequired,
    name: string.isRequired,
    state: string.isRequired,
    versionId: number.isRequired,
  }).isRequired,
};

export default UnlicenseSlideSetListItem;
