import React from 'react';
import { useDispatch } from 'react-redux';
import { arrayOf, bool, shape, string } from 'prop-types';

import StarIcon from '@pelckmans/business-components/icons/Star';
import EllipsisVerticalIcon from '@pelckmans/business-components/icons/EllipsisVertical';
import StarOutlineIcon from '@pelckmans/business-components/icons/StarOutline';

import { useTranslation } from 'react-i18next';
import { openNotLicensedModal } from '../../../../../../../../actions/dialog';
import MaterialInfo from '../MaterialInfo';

const UnlicensedMedialinkItem = ({ medialink }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemClickHandler = async e => {
    e.preventDefault();
    dispatch(openNotLicensedModal(t('notLicensed.accessContent.title'), t('notLicensed.accessContent.message')));
  };

  return (
    <li className="pbb-list__item" data-testid="medialink" data-id={medialink.id}>
      <div className="pbb-list__link-wrap pbb-list__link-wrap--disabled">
        <button type="button" className="pbb-list__link pbb-flex-wrap" title={medialink.name} onClick={itemClickHandler}>
          <MaterialInfo material={medialink} materialType="medialink" />
          <div className="pbb-list__actions">
            <div className="pbb-list__action">{medialink.isFavorite ? <StarIcon /> : <StarOutlineIcon />}</div>
            <div className="pbb-list__action">
              <EllipsisVerticalIcon />
            </div>
          </div>
        </button>
      </div>
    </li>
  );
};

UnlicensedMedialinkItem.propTypes = {
  medialink: shape({
    id: string.isRequired,
    name: string.isRequired,
    moduleId: string.isRequired,
    kind: string.isRequired,
    shares: arrayOf(
      shape({
        id: string.isRequired,
        label: string,
      }),
    ),
    assignments: arrayOf(
      shape({
        id: string,
        from: string,
        to: string,
        repeatable: bool,
      }),
    ),
    isFavorite: bool,
  }).isRequired,
};

export default UnlicensedMedialinkItem;
