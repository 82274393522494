import React from 'react';
import { ModalDialog, Dialog } from '@pelckmans/business-components/components/dialog';
import { string } from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PORTAAL_URL } from '../../../../constants/constants';
import { closeDialog } from '../../../../actions/dialog';
import dialogTypes from '../../../../enums/dialogTypes';

export default function NotLicensedModal({ title, message }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeModal = () => dispatch(closeDialog(undefined, dialogTypes.NOT_LICENSED));

  return (
    <ModalDialog id="not-licensed-modal" closeTitle={t('actions.close')} closeOnClickOutside closeViaEscape onClose={closeModal} size="small">
      <Dialog.Header title={title} />
      <Dialog.Body>
        <div className="pbb-modal__body">
          <p>{message}</p>
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <a href={`${PORTAAL_URL}dashboard?showLicenseActivation`} className="bc-button__button bc-button__primary">
          {t(`notLicensed.actions.activateLicense`)}
        </a>
      </Dialog.Footer>
    </ModalDialog>
  );
}

NotLicensedModal.propTypes = {
  title: string.isRequired,
  message: string.isRequired,
};
