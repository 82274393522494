import React from 'react';
import { object, string, func, shape, number, bool } from 'prop-types';
import File from '../medialinkTypes/file';
import MedialinkTypes from '../../../../../enums/medialinktype';
import ExternalMediaTypes from '../../../../../enums/externalmediatype';

const MediaLinkBody = ({ medialink, type, onFullScreenClick, dialogSize, close, isFullScreen, modalPosition }) => {
  const { externalMedia, name, imagePreview } = medialink;
  switch (medialink.kind) {
    case MedialinkTypes.FILE: {
      return (
        <File
          file={medialink.file}
          medialinkId={medialink.id}
          type={type}
          imagePreview={imagePreview}
          onFullScreenClick={onFullScreenClick}
          isFullScreen={isFullScreen}
          dialogSize={dialogSize}
          modalPosition={modalPosition}
          close={close}
          jwplayer={medialink.jwplayer}
        />
      );
    }

    case MedialinkTypes.EXTERNAL_MEDIA: {
      switch (externalMedia.type) {
        case ExternalMediaTypes.YOUTUBE: {
          const { embedId, start, end } = externalMedia;
          const startParam = start ? `&start=${start}` : '';
          const endParam = end ? `&end=${end}` : '';
          return (
            <div className="jw-video">
              <div className="pbb-modal__iframe-wrapper">
                <iframe
                  title={name}
                  className="iframe--fill iframe--no-border"
                  src={`https://www.youtube.com/embed/${embedId}?autoplay=1${startParam}${endParam}`}
                  role="application"
                />
              </div>
            </div>
          );
        }
        case ExternalMediaTypes.H5P:
          return isFullScreen ? (
            <div className="pbb-modal__iframe-wrapper">
              <iframe className="iframe--fill iframe--no-border" title={name} src={`https://h5p.org/h5p/embed/${externalMedia.embedId}`} allow="fullscreen" role="application" />
            </div>
          ) : (
            <iframe className="iframe--fill iframe--no-border" title={name} src={`https://h5p.org/h5p/embed/${externalMedia.embedId}`} allow="fullscreen" role="application" />
          );
        case ExternalMediaTypes.GEOGEBRA:
          return (
            <iframe
              // eslint-disable-next-line max-len
              src={`https://www.geogebra.org/material/iframe/id/${externalMedia.embedId}/width/${externalMedia.width}/height/${externalMedia.height}/border/888888/smb/${externalMedia.geogebra.menuBar}/stb/${externalMedia.geogebra.toolbar}/stbh/${externalMedia.geogebra.toolbarHelp}/ai/${externalMedia.geogebra.inputbar}/asb/${externalMedia.geogebra.stylebar}/sri/${externalMedia.geogebra.resetIcon}/rc/${externalMedia.geogebra.rightClick}/ld/${externalMedia.geogebra.labelDragging}/sdz/${externalMedia.geogebra.shiftDrag}/ctl/false?lang=${externalMedia.geogebra.language}`}
              title={name}
              className="iframe--no-border"
              role="application"
              // iOS 12 bug:
              //  when width of iframe is larger then window make sure it does not show a scrollbar which resizes the modal to the correct width
              //  width, height and scrolling needs to be present
              width={`${externalMedia.width}px`}
              height={`${externalMedia.height}px`}
              scrolling="no"
            />
          );
        default:
          return null;
      }
    }
    default:
      return null;
  }
};

MediaLinkBody.propTypes = {
  medialink: object.isRequired,
  type: string,
  onFullScreenClick: func,
  dialogSize: shape({ width: number, height: number }),
  isFullScreen: bool,
  close: func,
  modalPosition: shape({ x: number, y: number }),
};

MediaLinkBody.defaultProps = {
  close: () => {},
  onFullScreenClick: () => {},
  dialogSize: {},
  isFullScreen: false,
  type: undefined,
  modalPosition: undefined,
};

export default MediaLinkBody;
