import React, { useContext } from 'react';
import classNames from 'classnames';
import { string, shape } from 'prop-types';
import { useSelector } from 'react-redux';

import SlidingButton from './items/slidingButton';
import DrawerButtonsSidebarItem from './items/drawerButtons';
import NavigationSidebarItem from './items/navigation';
import ToolsSidebarItem from './items/tools';

import Drawer from './drawer';

import { getTeacherFeaturesEnabledFor } from '../../../../selectors/digibooks';
import { UserSettingsContext } from '../../context/user-settings-context';
import { getIsRendered } from '../../../../selectors/player';

const panelRoot = 'pbb-panel';

export default function Sidebar(props) {
  const { superModuleId, digibook } = props;
  const { sidebarAnchor, activeDrawer, setActiveDrawer, isSidebarOpen, setSidebarOpened, setShowMarkings } = useContext(UserSettingsContext);

  const bookIsRendered = useSelector(getIsRendered);
  const teacherFeaturesEnabled = useSelector(getTeacherFeaturesEnabledFor);

  return (
    <div className={classNames(panelRoot, `${panelRoot}--${sidebarAnchor}`)}>
      <div className="pbb-sidebar">
        {bookIsRendered && <SlidingButton superModuleId={superModuleId} slideOutDelay={500} />}
        <div className="pbb-sidebar__divider" />
        <ToolsSidebarItem setShowMarkings={setShowMarkings} />
        <div className="pbb-sidebar__divider" />
        <div className="pbb-sidebar__spacer" />
        <DrawerButtonsSidebarItem
          onClick={active => {
            if (active !== activeDrawer) {
              setActiveDrawer(active);
              if (!isSidebarOpen) setSidebarOpened(true);
            } else {
              setSidebarOpened(!isSidebarOpen);
            }
          }}
          isOpen={isSidebarOpen}
          activeDrawer={activeDrawer}
        />
        <NavigationSidebarItem />
        <div className="pbb-sidebar__divider" />
        <div className="pbb-sidebar__spacer" />
      </div>
      <Drawer isOpen={isSidebarOpen} activeDrawer={activeDrawer} superModuleId={superModuleId} digibook={digibook} teacherFeaturesEnabled={teacherFeaturesEnabled} />
    </div>
  );
}

Sidebar.propTypes = {
  // Own Props
  superModuleId: string,
  digibook: shape({
    id: string.isRequired,
    module: string.isRequired,
  }).isRequired,
};

Sidebar.defaultProps = {
  superModuleId: undefined,
};
