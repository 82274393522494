import PlusInCircleIcon from '@pelckmans/business-components/icons/PlusInCircle';
import { bool, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../enums/analytics';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { getCurrentModuleId } from '../../../../../../selectors/digibooks';
import api from '../../../../../../services/api';
import { buildDoceoUrl } from '../../../../../../utils/buildDoceoUrl';

function AddButton({ text, toolTip, nodeId, disabled }) {
  const moduleId = useSelector(getCurrentModuleId);
  const analytics = useAnalytics();

  const onAddSlideSet = async () => {
    const { data } = await api.post(`/studio/user/modules/${moduleId}/table-of-content/${nodeId}/slide-sets`);

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_CREATED,
      objectId: data.id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });

    return window.open(buildDoceoUrl(data, true), '_blank', 'noopener,noreferrer');
  };

  return (
    <li className="pbb-list__item">
      <div
        className={classNames('pbb-list__link-wrap', {
          'pbb-list__link-wrap--disabled': disabled,
        })}
      >
        <button disabled={disabled} type="button" onClick={onAddSlideSet} className="pbb-list__link" title={toolTip}>
          <PlusInCircleIcon className="pbb-list__link-icon" />
          <span className="pbb-list__link-text">{text}</span>
        </button>
      </div>
    </li>
  );
}

AddButton.propTypes = {
  text: string.isRequired,
  toolTip: string.isRequired,
  nodeId: string.isRequired,
  disabled: bool,
};

AddButton.defaultProps = {
  disabled: false,
};

export default AddButton;
